import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@app/components/Button';
import { useGetCartId } from '@app/context/CartIdProvider/CartIdProvider';
import { pushPerpetualEcommerceEvent } from '@app/helpers/tracking';
import useAddProductsToCart from '@app/hooks/useAddProductsToCart';
import { useRemoveCartItem } from '@app/hooks/useRemoveCartItem';
import useSimpleCart from '@app/hooks/useSimpleCart';
import type { Magento2_CartItemInput } from '@__generated__/globalTypes';

import messages from './QuickAddToCartButton.messages';

const QuickAddToCartButton = ({ sku }: { sku: string }) => {
  const [loading, setLoading] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const { loading: cartIdLoading } = useGetCartId();
  const { removeItem, loading: removeItemLoading } = useRemoveCartItem();
  const { addItems, addProductsLoading } = useAddProductsToCart();
  const { items, loading: cartLoadingStatus } = useSimpleCart();

  const cartLoading =
    cartIdLoading ||
    cartLoadingStatus ||
    addProductsLoading ||
    removeItemLoading;

  const getCartProduct = useCallback(() => {
    return items?.find((item) => item?.product.sku === sku);
  }, [items, sku]);

  useEffect(() => {
    if (!cartLoading) {
      setLoading(false);
    }

    if (typeof getCartProduct() !== 'undefined') {
      setAddedToCart(true);
    } else {
      setAddedToCart(false);
    }
  }, [cartLoading, getCartProduct, items, sku]);

  const addToCart = useCallback(() => {
    const cartItem: Magento2_CartItemInput = {
      sku,
      quantity: 1,
    };

    setLoading(true);
    addItems([cartItem]);

    const product = getCartProduct();
    const finalPrice = product?.prices?.priceIncludingTax;

    pushPerpetualEcommerceEvent('add_to_cart', {
      currency: finalPrice?.currency,
      value: finalPrice?.value,
      items: [
        {
          item_id: product?.product.sku || '',
          item_name: product?.product.name || '',
          price: finalPrice?.value || 0,
          quantity: 1,
        },
      ],
    });
  }, [addItems, sku, getCartProduct]);

  const removeFromCart = useCallback(() => {
    const uid = getCartProduct()?.uid;
    if (uid) {
      setLoading(true);
      removeItem(uid);
    }
  }, [getCartProduct, removeItem]);

  const onButtonClick = () => {
    if (cartLoading) return;

    if (addedToCart) {
      removeFromCart();
    } else {
      addToCart();
    }
  };

  return (
    <Button
      isLoading={loading}
      disabled={loading}
      type={addedToCart ? 'secondary' : 'primary'}
      onClick={onButtonClick}
      fullWidth
    >
      {!addedToCart && !loading && <FormattedMessage {...messages.add} />}
      {addedToCart && !loading && <FormattedMessage {...messages.remove} />}
    </Button>
  );
};

export default QuickAddToCartButton;
