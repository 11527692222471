import type { ComponentClass, ComponentType } from 'react';
import { Component, Fragment } from 'react';

import captureError from '@app/helpers/sentry';

interface ErrorState {
  hasError: boolean;
}

/**
 * ComponentErrorBoundary is intended to be wrapped around components in order
 * to catch errors caused by this component and optionally
 * show a fallback component.
 *
 * @param WrappedComponent - component that is wrapped
 * @param FallbackComponent - component that should be displayed in case
 *                            of an error
 */
const ComponentErrorBoundary = <
  // During NI migration this file will be deleted, so this is fine
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TProps extends Record<string, any>,
>(
  WrappedComponent: ComponentType<TProps>,
  FallbackComponent: ComponentType = Fragment,
): ComponentClass<TProps> => {
  return class ErrorBoundaryClass extends Component<TProps> {
    public state: ErrorState = { hasError: false };

    public displayName = `ComponentErrorBoundary(${WrappedComponent.displayName})`;

    public static getDerivedStateFromError(error: Error): ErrorState {
      const { message, stack } = error;
      captureError({
        name: 'Component Crashed',
        message,
        stack,
        tags: [
          { name: 'error_type', value: 'componentError' },
          {
            name: 'component_name',
            value: WrappedComponent.displayName || 'Unknown Component',
          },
        ],
      });
      return { hasError: true };
    }

    public render() {
      if (this.state.hasError) {
        return <FallbackComponent />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default ComponentErrorBoundary;
