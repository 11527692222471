import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useGetCartId } from '@app/context/CartIdProvider/CartIdProvider';
import { isNotNullish } from '@app/helpers/predicate';
import type {
  AddProductsToCartMutation,
  AddProductsToCartMutationVariables,
} from '@app/hooks/__generated__/AddProductsToCartMutation';
import { CART_UPDATE_RESULT_FRAGMENT } from '@app/pages/Cart/Cart.queries';

interface CartError {
  code?: string;
  message?: string;
}

export const ADD_PRODUCTS_TO_CART_MUTATION = gql`
  ${CART_UPDATE_RESULT_FRAGMENT}

  mutation AddProductsToCartMutation(
    $cartId: String!
    $cartItems: [Magento2_CartItemInput!]!
  ) {
    magento2 {
      addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
        cart {
          ...CartUpdateResult
        }
        userErrors {
          code
          message
        }
      }
    }
  }
`;

const useAddProductsToCart = () => {
  const { cartId } = useGetCartId();
  const [addProductsToCart, addProductsResult] = useMutation<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >(ADD_PRODUCTS_TO_CART_MUTATION);

  const addItems = useCallback(
    async (items) => {
      if (!cartId) return;

      await addProductsToCart({ variables: { cartId, cartItems: items } });
    },
    [cartId, addProductsToCart],
  );

  const addToCartErrors = [
    addProductsResult.error,
    ...(addProductsResult.data?.magento2?.addProductsToCart?.userErrors?.filter(
      Boolean,
    ) ?? []),
  ].filter(isNotNullish) as CartError[];

  return {
    addProductsToCart,
    addProductsResult,
    addItems,
    addToCartErrors,
    addProductsLoading: addProductsResult.loading,
  };
};

export default useAddProductsToCart;
