import {
  createContext,
  useContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';
import { useApolloClient } from '@apollo/client';

import { logGraphqlClientError } from '@app/helpers/logGraphqlClientError';
import useAuthentication from '@app/hooks/useAuthentication';

import {
  CreateEmptyCartDocument,
  CustomerCartIdDocument,
  GuestCartIdDocument,
  MergeCartsDocument,
  type CreateEmptyCartMutation,
  type CustomerCartIdQuery,
  type GuestCartIdQuery,
  type GuestCartIdQueryVariables,
  type MergeCartsMutation,
  type MergeCartsMutationVariables,
} from '../__graphql__/CartIdProvider';

const GUEST_CART_ID_STORAGE_KEY = 'shop/guest-cart-id';

export { CartIdProvider, useGetCartId };

/**
 * CartIdProvider fetches and stores
 * the currently valid user's cartId (guest or customer)
 * */
const CartIdContext = createContext<CartIdProviderValue>({
  cartId: '',
  cartUserType: '',
  loading: true,
  error: null,
} as CartIdProviderValue);

type CartIdProviderValue = {
  cartId?: string;
  cartUserType?: string;
  loading?: boolean;
  error?: Error | null;
};

function CartIdProvider({ children }: PropsWithChildren & CartIdProviderValue) {
  const { isAuthenticated, loading: authLoading } = useAuthentication();
  const client = useApolloClient();

  const [cartIdState, setCartIdState] = useState<CartIdProviderValue>({
    cartId: '',
    cartUserType: '',
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (authLoading) return;

    const fetchCartId = async () => {
      const {
        cartId: fetchedCartId,
        cartUserType: fetchedCartUserType,
        error: fetchedError,
      } = isAuthenticated
        ? await getCustomerCartId(client)
        : await getGuestCartId(client);

      setCartIdState({
        error: fetchedError,
        cartUserType: fetchedCartUserType,
        cartId: fetchedCartId,
        loading: false,
      });
    };

    fetchCartId();
  }, [authLoading, isAuthenticated, client]);

  return (
    <CartIdContext.Provider value={cartIdState}>
      {children}
    </CartIdContext.Provider>
  );
}

function useGetCartId() {
  return useContext(CartIdContext);
}

/**
 * CartIdProvider helpers
 */
type ClientType = ReturnType<typeof useApolloClient>;

async function getGuestCartId(client: ClientType) {
  let error: Error | null = null;

  const storedGuestCartId = getStoredGuestCartId();

  if (storedGuestCartId) {
    const {
      data,
      error: guestCartIdError,
      errors: guestCartIdErrors,
      loading,
    } = await client.query<GuestCartIdQuery, GuestCartIdQueryVariables>({
      query: GuestCartIdDocument,
      fetchPolicy: 'network-only',
      variables: { id: storedGuestCartId },
    });

    if (guestCartIdError || guestCartIdErrors || !data?.magento2?.cart?.id) {
      /* [WWW-11736] @TODO Log a trace/debug/info log here for helping debugging.
       * E.g. `No id found for ${storedGuestCartId} on the current store, creating a new one.`
       */

      const { newCartId, error: getCartIdError } =
        await getCartIdFromNewCart(client);

      if (getCartIdError || !newCartId) {
        error = new Error(
          'Could not get the cartId from the newly created cart',
        );

        return { cartId: '', cartUserType: 'guest', loading: false, error };
      }

      if ((guestCartIdError || guestCartIdErrors) && !newCartId) {
        logGraphqlClientError({
          errors: [
            `Failed to create a new cart ID after guest card ID failure: ${
              guestCartIdError?.message || guestCartIdErrors?.[0]?.message
            }`,
          ],
          operationName: 'GuestCartId',
        });
      }

      if (newCartId) storeGuestCartId(newCartId);

      return {
        cartId: newCartId,
        cartUserType: 'guest',
        loading,
        error: null,
      };
    }

    return { cartId: storedGuestCartId, cartUserType: 'guest', loading, error };
  }

  const { newCartId, error: getCartIdError } =
    await getCartIdFromNewCart(client);

  if (getCartIdError || !newCartId) {
    error = new Error('Could not get the cartId from the newly created cart');

    return { cartId: '', cartUserType: 'guest', loading: false, error };
  }

  storeGuestCartId(newCartId);

  return {
    cartId: newCartId,
    cartUserType: 'guest',
    loading: false,
    error: null,
  };
}

async function getCustomerCartId(client: ClientType) {
  let error: Error | null = null;

  const {
    data,
    error: customerCartIdError,
    errors: customerCartIdErrors,
    loading,
  } = await client.query<CustomerCartIdQuery>({
    query: CustomerCartIdDocument,
    fetchPolicy: 'network-only',
  });

  const customerCartId = data?.magento2?.customerCart?.id;

  if (customerCartIdError || customerCartIdErrors || !customerCartId) {
    error = new Error('Could not get the customerCartId');

    return { cartId: '', cartUserType: 'customer', loading, error };
  }

  const storedGuestCartId = getStoredGuestCartId();

  if (!storedGuestCartId) {
    return { cartId: customerCartId, cartUserType: 'customer', loading, error };
  }

  if (storedGuestCartId === customerCartId) {
    return {
      cartId: customerCartId,
      cartUserType: 'customer',
      loading,
      error: null,
    };
  }

  const { mergedCartId, mergeError } = await mergeCarts({
    storedGuestCartId,
    customerCartId,
    client,
  });

  if (mergedCartId || customerCartId) {
    removeStoredGuestCartId();
  }

  if (mergeError || !mergedCartId) {
    /* [WWW-11736] @TODO Log a trace/debug/info log here for helping debugging
     * E.g. `Could not get cartId after merging carts: sourceId: ${storedGuestCartId}, destinationId: ${customerCartId}.
     * Returning customerCartId instead. ${mergeError ?? '' */

    if (!customerCartId) {
      logGraphqlClientError({
        errors: [
          `Failed to get customer cart ID after merging carts: ${mergeError?.toString()}`,
        ],
        operationName: 'MergeCarts',
      });
    }

    return {
      cartId: customerCartId,
      cartUserType: 'customer',
      loading,
      error,
    };
  }

  return {
    cartId: mergedCartId,
    cartUserType: 'customer',
    loading,
    error,
  };
}

async function getCartIdFromNewCart(client: ClientType) {
  try {
    const { data, errors: createEmptyCartErrors } =
      await client.mutate<CreateEmptyCartMutation>({
        mutation: CreateEmptyCartDocument,
      });

    const guestCartId = data?.magento2?.createEmptyCart;

    if (!guestCartId || createEmptyCartErrors) {
      return {
        newCartId: '',
        error: new Error(
          'Could not get the cartId from the newly created cart',
        ),
      };
    }

    return { newCartId: guestCartId, error: null };
  } catch (e) {
    return {
      newCartId: '',
      error: new Error('Could not create empty cart'),
    };
  }
}

type MergeCartsProps = {
  storedGuestCartId: string;
  customerCartId: string;
  client: ClientType;
};

async function mergeCarts({
  storedGuestCartId,
  customerCartId,
  client,
}: MergeCartsProps) {
  try {
    const { data: mergeData, errors: mergeCartErrors } = await client.mutate<
      MergeCartsMutation,
      MergeCartsMutationVariables
    >({
      mutation: MergeCartsDocument,
      variables: { sourceId: storedGuestCartId, destinationId: customerCartId },
    });

    const mergedCartId = mergeData?.magento2?.mergeCarts?.id;

    return {
      mergedCartId,
      mergeError: mergeCartErrors ?? null,
    };
  } catch (error) {
    return {
      mergedCartId: '',
      mergeError: (error as Error) ?? null,
    };
  }
}

const storeGuestCartId = (cartId: string) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(GUEST_CART_ID_STORAGE_KEY, cartId);
  }
};

const getStoredGuestCartId = () =>
  typeof localStorage !== 'undefined'
    ? localStorage.getItem(GUEST_CART_ID_STORAGE_KEY)
    : null;

const removeStoredGuestCartId = () =>
  typeof localStorage !== 'undefined'
    ? localStorage.removeItem(GUEST_CART_ID_STORAGE_KEY)
    : null;
