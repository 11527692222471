import { useCallback } from 'react';
import type { Props } from 'react-modal';
import ReactModal from 'react-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { ModalState } from '../../redux/modal';
import { closeModal } from '../../redux/modal';

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('.root-element');
}

export interface ModalProps extends Omit<ModalState, 'label'> {
  contentLabel: ModalState['label'];
  shouldCloseOnEsc?: boolean;
}

const mapStateToProps = ({
  modal,
}: {
  modal: ModalState;
}): Omit<ModalProps, 'closeModal'> => ({
  ...modal,
  contentLabel: modal.label,
  shouldCloseOnEsc: modal.closeOnEscape || false,
  additionalClass: modal.additionalClass || '',
});

const Modal = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const { content, isOpen, contentLabel, additionalClass, ...state } =
    useSelector(mapStateToProps, shallowEqual);
  const onRequestClose = useCallback(() => dispatch(closeModal()), [dispatch]);
  const customClass = additionalClass ? ` ${additionalClass}` : '';

  return (
    <ReactModal
      {...props}
      overlayClassName={'ReactModal-overlay' + customClass}
      className={'ReactModal-content' + customClass}
      bodyOpenClassName={'ReactModal-body-open' + customClass}
      onRequestClose={onRequestClose}
      isOpen={Boolean(isOpen)}
      // @ts-expect-error seems to legitimately accept react elements though not typed as such
      contentLabel={contentLabel}
      {...state}
    >
      {content}
    </ReactModal>
  );
};

export default Modal;
