/*
 * fragment matcher module to resolve types in cache
 * https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
 * UPDATE: FragmentMatchers deprecated in @apollo/client v3:
 * https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#breaking-cache-changes
 */
import {
  ApolloClientOptions,
  defaultDataIdFromObject,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  StoreObject,
} from '@apollo/client';

import { localQueryFields, localTypeDefs } from './localState';

export const typeDefs: ApolloClientOptions<NormalizedCacheObject>['typeDefs'] =
  [localTypeDefs];

export const typePolicies: NonNullable<InMemoryCacheConfig['typePolicies']> = {
  Query: {
    fields: localQueryFields,
  },
  Magento2: {
    keyFields: [],
  },
  Magento2Mutation: {
    keyFields: [],
  },
  Magento2_Customer: {
    keyFields: ['nativeId'],
  },
  Magento2_StoreRecord: {
    keyFields: ['storeCode'],
  },
  Magento2_StoreConfig: {
    keyFields: ['storeCode'],
  },
  Magento2_CheckoutAgreement: {
    keyFields: ['agreementId'],
  },
  Magento2_AvailablePaymentMethod: {
    keyFields: ['code'],
  },
  Magento2_SelectedPaymentMethod: {
    keyFields: ['code'],
  },
  Magento2_AppliedGiftCard: {
    keyFields: ['code'],
  },
  Magento2_Cart: {
    fields: { appliedGiftCards: { merge } },
  },
  Magento2_GlobalCustomerOrder: {
    fields: { items: { merge } },
  },
  content: {
    keyFields: [],
  },
};

/*
 * Magento 2 uses `uid` as a default identifier.
 *
 * So we support `uid` as a possible cache key for all GraphQL typenames,
 * as well as Apollo's defaults (@apollo/client#defaultDataIdFromObject).
 *
 * Docs: github.com/native-instruments/website-frontend/wiki/Apollo-Client#cache
 */
export const dataIdFromObject = (
  storeObject: Readonly<StoreObject>,
): string | undefined => {
  const defaultApolloId = defaultDataIdFromObject(storeObject);

  if (defaultApolloId) {
    return defaultApolloId;
  } else if (typeof storeObject?.__typename === 'string' && storeObject?.uid) {
    return `${storeObject.__typename}:${storeObject.uid}`;
  }
};

// apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
function merge(_existing = [], incoming: unknown[]) {
  return incoming && [...incoming];
}
