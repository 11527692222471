import cookies from 'js-cookie';

import { COOKIE_DOMAIN } from '@config/client.config';

const getCookieDomainPermutaions = (domain: string) => [
  domain,
  `.${domain}`,
  `www.${domain}`,
  `.www.${domain}`,
];

// this is duplicated from nicom-login, shared lib?
export const clearAndSetCookie = (cookieName: string, value: string): void => {
  const rootDomain = window.location.hostname.replace(
    /^(https?:\/\/)?(www.)?/,
    '',
  );

  // We want to ensure, where we have control, that only one
  // instance of a cookie exists. on some envs like testing and staging,
  // we often have situations where a localization_country cookie can be set
  // on .native-instruments, .testing, .www.testing, www.testing, etc
  // which don't match the COOKIE_DOMAIN var. only do this on non-prod envs
  if (
    typeof window !== 'undefined' &&
    !/^native-instruments\./.test(rootDomain)
  ) {
    try {
      [
        ...getCookieDomainPermutaions(rootDomain),
        ...getCookieDomainPermutaions('native-instruments.com'),
      ].forEach((domain) => {
        cookies.remove(cookieName, { domain });
      });
    } catch (e) {
      // fail silently since this is not critical on prod
    }
  }

  cookies.set(cookieName, value, { domain: COOKIE_DOMAIN, secure: true });
};
