import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import getCategoryTranslation from '@app/hooks/useMenu/getCategoryTranslation';
import { getActiveCategory } from '@app/hooks/useMenu/helpers';
import type { Menu } from '@app/hooks/useMenu/types';

export const CATALOG_TITLE_TEMPLATE = `%s`;

export interface CatalogMetaProps {
  menu: Menu;
  menuSlugs: string[];
}

const CatalogMeta: FC<CatalogMetaProps> = ({ menu, menuSlugs }) => {
  const { language } = useParams<{ language: string }>();
  const menuItem = getActiveCategory(menu, menuSlugs);

  const translations = menuItem?.translations;
  const translation = getCategoryTranslation(translations, language);

  const categoryTitle = translation?.meta_title || translation?.title || '';
  const categoryMeta = translation?.meta_description || '';

  const categoryMetaImage = menuItem?.meta_image?.full_url || '';
  const categoryMetaImageAlt = menuItem?.meta_image_alt || '';

  return (
    <Helmet titleTemplate={CATALOG_TITLE_TEMPLATE} title={categoryTitle}>
      {categoryMeta && <meta name="description" content={categoryMeta} />}
      {categoryTitle && <meta property="og:title" content={categoryTitle} />}
      {categoryMeta && (
        <meta property="og:description" content={categoryMeta} />
      )}
      {categoryMetaImage && (
        <meta property="og:image" content={categoryMetaImage} />
      )}
      {categoryMetaImageAlt && (
        <meta property="og:image:alt" content={categoryMetaImageAlt} />
      )}
    </Helmet>
  );
};

export default CatalogMeta;
