import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import CheckIcon from '@app/assets/icons/CheckIcon';
import CrossIcon from '@app/assets/icons/CrossIcon';
import theme from '@app/styles/theme';
import { rem } from '@app/styles/theme/layout';

import logoImage from '../../assets/images/ni-logo.svg';

enum AuthState {
  PendingVerification = 'PENDING_VERIFICATION',
  Authenticated = 'AUTHENTICATED',
  AuthenticationFailed = 'AUTHENTICATION_FAILED',
}

const getAuthState = (error, errorDescription, code) => {
  if (
    error === 'access_denied' ||
    errorDescription === 'same_email_in_other_profile'
  ) {
    return AuthState.PendingVerification;
  } else if (error || !code) {
    return AuthState.AuthenticationFailed;
  }
  return AuthState.Authenticated;
};

const iconSize = 40;
const svgSize = iconSize * 0.5;

type AuthResult = { authState: AuthState; link: string };

function getAuthResult(search: string): AuthResult {
  const queryParams = new URLSearchParams(search);
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');
  const code = queryParams.get('code');
  const authState = getAuthState(error, errorDescription, code);
  return {
    authState,
    link:
      authState === AuthState.Authenticated
        ? `native-access://authorize?code=${code}`
        : 'https://support.native-instruments.com/',
  };
}

const AuthHandlerContent = () => {
  const { search } = useLocation();

  const { link, authState } = useMemo(() => getAuthResult(search), [search]);

  useEffect(() => {
    if (authState === AuthState.Authenticated) {
      window.location.href = link;
    }
  }, [authState, link]);

  switch (authState) {
    case AuthState.PendingVerification:
      return (
        <>
          <StatusIconWrapper>
            <StatusIcon color={theme.colors.red} aria-label="Error cross">
              <CrossIcon
                width={svgSize}
                height={svgSize}
                fill={theme.colors.white}
              />
            </StatusIcon>
          </StatusIconWrapper>
          <Heading>Verification needed</Heading>
          <p>
            You account needs to be verified before you can log in. Please check
            your email and follow the link to verify your account.
          </p>
          <p>
            If you haven&apos;t recieved and email, please reach out to{' '}
            <HelpLink href={link}>customer support.</HelpLink>
          </p>
        </>
      );
    case AuthState.AuthenticationFailed:
      return (
        <>
          <StatusIconWrapper>
            <StatusIcon color={theme.colors.red} aria-label="Error cross">
              <CrossIcon
                width={svgSize}
                height={svgSize}
                fill={theme.colors.white}
              />
            </StatusIcon>
          </StatusIconWrapper>
          <Heading>We are unable to log you in</Heading>
          <p>
            If you continue to experience this issue, please reach out to{' '}
            <HelpLink href={link}>customer support.</HelpLink>
          </p>{' '}
        </>
      );
    default:
      return (
        <>
          <StatusIconWrapper>
            <StatusIcon
              color={theme.colors.acidGreen}
              aria-label="Success check mark"
            >
              <CheckIcon
                width={svgSize}
                height={svgSize}
                fill={theme.colors.white}
              />
            </StatusIcon>
          </StatusIconWrapper>
          <Heading>You are now logged in!</Heading>
          <p>
            Native Access should open automatically, you can close this window.
          </p>
          <p>
            If you aren’t redirected automatically, use{' '}
            <HelpLink href={link}>
              this link to return to Native Access.
            </HelpLink>
          </p>
        </>
      );
  }
};

const NaAuthHandler = () => {
  return (
    <Container>
      <LoginBox>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <StatusWrapper>
          <AuthHandlerContent />
        </StatusWrapper>
      </LoginBox>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  background-color: ${theme.colors.white};
`;

const Logo = styled.div`
  width: ${rem(42)};
  display: block;
  height: ${rem(24)};
  transition: width 0.3s;
  justify-self: flex-start;
  span {
    display: none;
  }
  background: url(${logoImage}) no-repeat;
`;

const LoginBox = styled.div`
  height: 100vh;
  background-color: ${theme.colors.white};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  padding: 64px;
`;

const StatusWrapper = styled.div`
  text-align: center;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 8px;
  max-width: 450px;
  padding: 64px;
  flex-direction: column;
  display: flex;
`;

const Heading = styled.h2`
  color: ${theme.colors.darkGray};
`;

const StatusIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const StatusIcon = styled.div<{ color: string }>`
  border-radius: ${iconSize}px;
  width: ${iconSize}px;
  padding: ${(iconSize - svgSize) * 0.5}px;
  height: ${iconSize}px;
  ${({ color }) => `background-color: ${color};`}
`;

const HelpLink = styled.a`
  color: ${theme.colors.darkGray};
  font-weight: bold;
  text-decoration: underline;
`;

export default NaAuthHandler;
