import { Box } from '@theme-ui/components';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screenSmall } from '../../../styles/theme/breakpoints';
import { rem } from '../../../styles/theme/layout';

export const IMAGE_STYLE_FULL_WIDTH = 'full-width';
export const IMAGE_STYLE_FIXED = 'fixed';
const CONTENT_POSITION_LEFT = 'left';
const CONTENT_POSITION_RIGHT = 'right';

export const layoutWidth = ['100%', `80%`];

export const ImageWrapper = styled(Box)`
  line-height: 0;

  @media screen and (min-width: ${screenSmall}) {
    max-width: ${rem(520)};
    padding-right: ${rem(50)};
    padding-left: ${rem(50)};
  }
`;

const flexContainerSeamless = (): SerializedStyles => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: ${screenSmall}) {
    position: static;
    transform: translateY(0);
  }
`;

export const flexContainerStyles = ({
  imageStyle,
  textPosition,
}: {
  imageStyle: string | null;
  textPosition: string | null;
}): SerializedStyles => css`
  margin: 0 auto;
  flex-wrap: wrap;
  font-size: ${rem(16)};
  line-height: 1.5;

  ${imageStyle === IMAGE_STYLE_FULL_WIDTH && flexContainerSeamless()};
  flex-direction: ${textPosition === 'left' ? 'row' : 'row-reverse'};
  left: ${textPosition === CONTENT_POSITION_LEFT && 0};
  right: ${textPosition === CONTENT_POSITION_RIGHT && 0};

  h2,
  h3 {
    font-size: ${rem(48)};
    margin-bottom: ${rem(20)};
    line-height: 1.15;
  }

  @media screen and (max-width: ${screenSmall}) {
    h2,
    h3 {
      font-size: ${rem(30)};
      margin-bottom: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }
`;
