import { lazy, Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@theme-ui/components';

import { APP_BASE_URL } from '../../../config/client.config';
import CartButton from '../CartButton/CartButton';
import { SubMenu } from './SubMenu/SubMenu';
import AccountWidget from './AccountWidget';
import messages from './Header.messages';
import styles from './Header.styles';

const StoreSwitch = lazy(
  () =>
    import(
      /*webpackChunkName: 'StoreSwitch' */ '@app/components/StoreSwitch/StoreSwitch'
    ),
);

const Header = ({ subMenu }: { subMenu?: boolean }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isProductHovered, setIsProductHovered] = useState(false);
  const isSubMenuVisible = (isProductHovered || isMenuOpen) && Boolean(subMenu);
  const { language } = useParams<{ language: string }>();
  const NAV_LINKS = {
    products: `/${language}/catalog/`,
    community: `/${language}/community/`,
    support: `http://support.native-instruments.com/hc/`,
  };

  return (
    <header css={styles.header}>
      <Flex css={styles.contentWrapper}>
        <Box mr={3} sx={{ flexBasis: ['auto', '20%'] }}>
          <a css={styles.logo} href={APP_BASE_URL}>
            <span>Native Instruments</span>
          </a>
        </Box>

        <Flex css={styles.navContainer} sx={{ flexBasis: ['auto', '65%'] }}>
          <nav css={styles.nav(isMenuOpen)}>
            <ul css={styles.navUl}>
              {Object.keys(messages).map((link) => {
                return link === 'products' && subMenu ? (
                  <li
                    data-testid="submenu-trigger"
                    onMouseEnter={() => setIsProductHovered(true)}
                    onMouseLeave={() => setIsProductHovered(false)}
                    css={styles.navLiWithTrigger}
                    key={link}
                  >
                    <a css={styles.navLink} href={NAV_LINKS[link]}>
                      <FormattedMessage {...messages[link]} />
                    </a>
                    <SubMenu isMenuVisible={isSubMenuVisible} />
                  </li>
                ) : (
                  <li css={styles.navLi} key={link}>
                    <a css={styles.navLink} href={NAV_LINKS[link]}>
                      <FormattedMessage {...messages[link]} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </Flex>

        <Flex css={styles.accountContainer} sx={{ flexBasis: ['auto', '15%'] }}>
          <Box mr={[0, 3]}>
            <Suspense fallback={null}>
              <StoreSwitch />
            </Suspense>
          </Box>
          <Box mr={[0, 3]}>
            <Suspense fallback={null}>
              <AccountWidget />
            </Suspense>
          </Box>
          <Box mr={[3, 0]}>
            <CartButton />
          </Box>

          <button
            css={styles.mobileMenu}
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <Text sx={{ display: 'block' }} pr={2}>
              MENU
            </Text>
            <div css={styles.hamburger(isMenuOpen)}>
              <span />
              <span />
              <span />
            </div>
          </button>
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
