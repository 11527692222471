import { defineMessages } from 'react-intl';

export default defineMessages({
  'full-version': {
    id: 'components.ProductPrice.license.full-version',
    defaultMessage: 'Full Version',
  },
  'update': {
    id: 'components.ProductPrice.license.update',
    defaultMessage: 'Update',
  },
  'upgrade': {
    id: 'components.ProductPrice.license.upgrade',
    defaultMessage: 'Upgrade',
  },
  'crossgrade': {
    id: 'components.ProductPrice.license.crossgrade',
    defaultMessage: 'Crossgrade',
  },
  'my-update': {
    id: 'components.ProductPrice.license.my-update',
    defaultMessage: 'My Update',
  },
  'my-upgrade': {
    id: 'components.ProductPrice.license.my-upgrade',
    defaultMessage: 'My Upgrade',
  },
  'my-crossgrade': {
    id: 'components.ProductPrice.license.my-crossgrade',
    defaultMessage: 'My Crossgrade',
  },
});
